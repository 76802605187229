import { useEffect, useMemo } from 'react'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import classNames from 'classnames'
import { Outlet } from 'react-router-dom'

import { Button, Divider, ErrorState, Image } from 'shared/components'
import environment from 'shared/environments/environment'
import { useRouter } from 'shared/hooks'
import { SnackbarProvider } from 'shared/providers'

import { EmptyState } from 'entities/components'
import { NavigationProvider } from 'entities/providers'

import GlobalAppContext from './context/GlobalAppContext'
import useGlobalStats from './hooks/useGlobalStats'
import useTelemetry from './hooks/useTelemetry'

// eslint-disable-next-line complexity
export function DashboardLayout() {
  const { loading, data, error, hasNoAccounts, fetch } = useGlobalStats()
  const { telemetry } = useTelemetry()
  const router = useRouter()
  const canRender = !error && !hasNoAccounts

  useEffect(() => {
    if (telemetry?.telemetry_enabled && environment.production) {
      Sentry.init({
        dsn: environment.SENTRY_URL,
        integrations: [new BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      })
    }
  }, [telemetry])

  const betaFlagOnboardingWizard = true as boolean // set this to true once wizard gets good support of the backend
  const isOnboarding =
    betaFlagOnboardingWizard && router.pathname.startsWith('/onboarding')

  const contextValue = useMemo(
    () => ({
      loading,
      data,
      error,
      hasNoAccounts,
      fetch,
      betaFlagOnboardingWizard,
    }),
    [betaFlagOnboardingWizard, data, error, fetch, hasNoAccounts, loading]
  )

  return (
    <GlobalAppContext.Provider value={contextValue}>
      <SnackbarProvider>
        {isOnboarding && (
          <>
            {/* TODO: Separate layout for onboarding - may not needed */}
            <Outlet />
          </>
        )}

        {!isOnboarding && (
          <div className="w-full h-[100vh]">
            <div className="px-4 py-2 flex items-center gap-2 h-[56px] border-b border-darkcyan-100">
              <Image
                src="/assets/svg/logo.svg"
                alt="logo"
                className="mt-[-4px]"
              />
              <Divider vertical />
              <span className="text-grey-800 text-lg">Title</span>
            </div>

            <div className="flex max-h-[calc(100vh-56px)]">
              <NavigationProvider>
                <main
                  className={classNames(
                    'relative bg-gray-50 p-6 pb-12 xl:px-8 2xl:px-24',
                    'overflow-y-auto w-full'
                  )}
                >
                  {canRender && <Outlet />}

                  {hasNoAccounts && betaFlagOnboardingWizard && (
                    //  !isOnboarding &&
                    <EmptyState
                      title="We could not find a cloud account"
                      message="Get Started Onboarding"
                      action={() => {
                        router.push('/onboarding/choose-database')
                      }}
                      actionLabel="Begin Onboarding"
                      secondaryAction={() => {}}
                      secondaryActionLabel="Report an issue"
                      mascotPose="greetings"
                    />
                  )}

                  {/* This block would be removed when onboarding Wizard is stable leaving the block above */}
                  {hasNoAccounts && !betaFlagOnboardingWizard && (
                    <EmptyState
                      title="We could not find a cloud account"
                      message="It seems you have not connected a cloud account to optimiser. Connect one right now so you can start managing it from your dashboard"
                      action={() => {}}
                      actionLabel="Guide to connect account"
                      secondaryAction={() => {}}
                      secondaryActionLabel="Report an issue"
                      mascotPose="thinking"
                    />
                  )}
                  {/* This block would be removed when onboarding Wizard is stable leaving the block above */}

                  {error && (
                    <ErrorState
                      title="Network request error"
                      message="There was an error fetching the cloud accounts. Please refer to the logs for more info and try again."
                      action={
                        <Button
                          size="lg"
                          style="secondary"
                          onClick={() => router.reload()}
                        >
                          Refresh the page
                        </Button>
                      }
                    />
                  )}
                </main>
              </NavigationProvider>
            </div>
          </div>
        )}
      </SnackbarProvider>
    </GlobalAppContext.Provider>
  )
}
