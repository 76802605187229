export function capitalizeString(inputString: string) {
  if (inputString.length < 0) return inputString

  return (
    inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
  )
}

export function decodeString(inputString: string) {
  if (inputString.length < 0) return inputString

  return decodeURIComponent(inputString.replace(/\s+/g, ' '))
}
