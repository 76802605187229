import { useQuery } from '@tanstack/react-query'

import { settingsApi } from '../../api/model/settings.api'

export function useOnboardingStatus() {
  const { data, ...restQuery } = useQuery({
    queryKey: ['onboarding-status'],
    queryFn: async () => {
      const { data } = await settingsApi.getOnboardingStatus()
      return data
    },
  })

  return { onboardingData: data, ...restQuery }
}
