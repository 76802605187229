import { ReactNode, useEffect } from 'react'

import { Avatar, Button } from 'shared/components'

import { Provider } from 'entities/utils/providerHelper'

import ArrowLeftIcon from 'features/components/icons/ArrowLeftIcon'
import HyperLinkIcon from 'features/components/icons/HyperLinkIcon'

type SidePanelProps = {
  isOpen: boolean
  closeModal: () => void
  children: ReactNode
  noScroll?: boolean
}

export function SidePanel({
  isOpen,
  closeModal,
  children,
  noScroll,
}: SidePanelProps) {
  // Listen to ESC key and close modal
  useEffect(() => {
    function escFunction(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        closeModal()
      }
    }

    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isOpen && (
        <>
          <div
            onClick={closeModal}
            className="fixed inset-0 z-30 hidden animate-fade-in bg-gray-900/30  sm:block"
          />
          <div
            className={`fixed inset-0 z-30 flex w-full animate-fade-in-up flex-col gap-4 overflow-auto bg-white px-8 pt-4 opacity-0 shadow-left sm:bottom-4 sm:left-auto sm:right-4 sm:top-4 sm:w-[38rem] sm:animate-fade-in-left sm:rounded-lg ${
              noScroll ? 'overflow-hidden' : 'overflow-auto'
            }`}
          >
            {children}
          </div>
        </>
      )}
    </>
  )
}

export type SidePanelHeaderProps = {
  title: string
  subtitle?: string
  href?: string
  cloudProvider?: Provider
  children?: ReactNode
  closeModal: () => void
  deleteAction?: () => void
  goBack?: () => void
  deleteLabel?: string
}

export function SidePanelHeader({
  title,
  subtitle,
  href,
  cloudProvider,
  children,
  closeModal,
  deleteAction,
  deleteLabel,
  goBack,
}: SidePanelHeaderProps) {
  return (
    <div
      className={`flex flex-wrap-reverse items-center justify-between gap-6 sm:flex-nowrap ${
        subtitle && 'pt-2'
      }`}
    >
      {title && subtitle && (
        <div className="flex flex-wrap items-center gap-2 sm:flex-nowrap">
          {cloudProvider && <Avatar avatarName={cloudProvider} size={40} />}
          <div className="flex flex-col gap-0.5">
            <p className="font-['Noto Sans'] text-neutral-900 inline-flex w-48 items-center gap-2 truncate text-base font-medium leading-normal">
              {title}
              <a
                target="_blank"
                href={href}
                rel="noreferrer"
                className="hover:text-darkcyan-500"
              >
                <HyperLinkIcon />
              </a>
            </p>
            <p className="font-['Noto Sans'] text-neutral-500 text-xs font-normal">
              {subtitle}
            </p>
          </div>
        </div>
      )}

      {title && !subtitle && (
        <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
          <button type="button" onClick={goBack}>
            <ArrowLeftIcon className="h-6 w-6" />
          </button>
          <div className="flex flex-col gap-0.5">
            <p className="font-['Noto Sans'] text-neutral-900 text-center text-xl font-semibold leading-loose">
              {title}
            </p>
          </div>
        </div>
      )}

      {children}

      <div className="flex flex-shrink-0 items-center gap-4">
        {deleteAction && (
          <Button style="delete" onClick={deleteAction}>
            {deleteLabel || 'Delete'}
          </Button>
        )}

        <Button style="secondary" onClick={closeModal}>
          Close
        </Button>
      </div>
    </div>
  )
}

export type SidePanelPageProps = {
  page: string
  param: string
  children: ReactNode
  container?: boolean
}

export function SidePanelPage({
  page,
  param,
  children,
  container = false,
}: SidePanelPageProps) {
  return (
    <>
      {page === param && (
        <div
          className={
            container ? 'rounded-lg bg-gray-50 p-6' : ' h-full overflow-auto'
          }
        >
          <div className="flex flex-col gap-6">{children}</div>
        </div>
      )}
    </>
  )
}
