import { ReactNode } from 'react'

import { Link as RouterLink } from 'react-router-dom'

// FIXME: This is a temporary solution for Link of next/link

export type LinkProps = {
  href: string
  children: ReactNode
  className?: string
  onClick?: () => void
}

export function Link({ href, children, className = '', onClick }: LinkProps) {
  return (
    <RouterLink onClick={onClick} to={href} className={className}>
      {children}
    </RouterLink>
  )
}
