import { Suspense } from 'react'

import { Loader } from 'shared/components'

// import formbricks from '@formbricks/js'
import './App.css'
import { I18nProvider, QueryProvider, RouterProvider } from './providers'

// if (typeof window !== 'undefined') {
//   printHiringMessage()
//   formbricks.init({
//     environmentId: environment.FORMBRICKS_ENV_ID,
//     apiHost: 'https://app.formbricks.com',
//   })
// }

function App() {
  // FIXME: Remove this when id not required or TODO: Formbricks is integrated - used to get review data
  //   useEffect(() => {
  //     // Connect next.js router to Formbricks
  //     const handleRouteChange = formbricks?.registerRouteChange
  //     router.events.on('routeChangeComplete', handleRouteChange)

  //     return () => {
  //       router.events.off('routeChangeComplete', handleRouteChange)
  //     }
  //   }, [])

  return (
    <I18nProvider>
      <QueryProvider>
        <Suspense fallback={<Loader fullView loading />}>
          <RouterProvider />
        </Suspense>
      </QueryProvider>
    </I18nProvider>
  )
}

export default App
