import classNames from 'classnames'

import { Button } from 'shared/components'

import AlertCircleIcon from 'features/components/icons/AlertCircleIcon'

import CheckIcon from '../../../features/components/icons/CheckIcon'
import ErrorIcon from '../../../features/components/icons/ErrorIcon'

import { SnackbarOptions } from './snackbar-context'

type Props = SnackbarOptions & {
  dismiss: () => void
}

function Snackbar({ type = 'info', title, message, dismiss }: Props) {
  const isError = type === 'error'
  const isSuccess = type === 'success'
  const isInfo = type === 'info'

  return (
    <>
      <div
        className={classNames(
          'fixed bottom-4 left-4 right-4 z-40 flex max-w-2xl animate-fade-in-up items-center justify-between overflow-hidden rounded-lg px-6 py-4 text-gray-950 opacity-0 shadow-right sm:left-8',
          {
            'bg-red-50': isError,
            'bg-green-100': isSuccess,
            'bg-blue-100': isInfo,
          }
        )}
      >
        <div
          className={classNames(
            'absolute bottom-0 left-0 h-1 animate-width-to-fit',
            {
              'bg-red-50': isError,
              'bg-green-500': isSuccess,
              'bg-blue-500': isInfo,
            }
          )}
        />
        <div className="flex items-start">
          <div
            className={classNames({
              'mt-4 text-red-500 ': isError,
              'mt-4 text-green-500': isSuccess,
              'mt-4 text-blue-500': isInfo,
            })}
          >
            {isError && <ErrorIcon width={24} height={24} />}
            {isSuccess && <CheckIcon width={24} height={24} />}
            {isInfo && <AlertCircleIcon width={24} height={24} />}
          </div>
          <div className="p-3">
            <p className="pb-2 text-sm font-medium">{title}</p>
            <p
              className="text-sm text-gray-950 "
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
        </div>
        <div className="w-12" />
        <Button style="text" onClick={dismiss}>
          Dismiss
        </Button>
      </div>
    </>
  )
}

export default Snackbar
