export type LoaderProps = {
  loading: boolean
  children?: React.ReactNode
  fullView?: boolean
}

export function Loader({ loading, children, fullView = false }: LoaderProps) {
  if (loading)
    return (
      <div>
        <div>
          {loading} {fullView}
        </div>
        <div>{children}</div>
      </div>
    )

  return <div>{children}</div>
}
