// Icon.jsx
import { ReactSVG } from 'react-svg'

import { BusinessIcons } from 'entities/assets'

export type BusinessIconName = keyof typeof BusinessIcons

export function BusinessIcon({
  name,
  className,
}: {
  name: BusinessIconName
  className?: string
}) {
  return <ReactSVG className={`${className}`} src={BusinessIcons[name]} />
}
