import { IClient, getClient } from 'shared/data-providers'

import { OnboardingStatus } from 'entities/definitions/model/settings.types'

export class SettingsAPI {
  private client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async getOnboardingStatus() {
    return this.client.get<OnboardingStatus>('is_onboarded')
  }
}

export const settingsApi = new SettingsAPI(getClient())
