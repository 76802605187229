import { useRouter } from 'shared/hooks'
import { SnackbarOptions } from 'shared/providers'

import settingsService from '../../../entities/services/settingsService'
import DownloadIcon from '../../../features/components/icons/DownloadIcon'
import { Button } from '../Button'
import { Tooltip } from '../Tooltip'

type ExportCSVButtonProps = {
  id?: string
  disabled: boolean
  exportCSV: (id?: string) => void
}

export function ExportCSVButton({
  id,
  disabled,
  exportCSV,
}: ExportCSVButtonProps) {
  return (
    <>
      <div className="peer flex flex-col">
        <Button
          style="dropdown"
          size="sm"
          disabled={disabled}
          onClick={() => exportCSV(id)}
        >
          <DownloadIcon width={24} height={24} />
          Download CSV
        </Button>
      </div>
      {disabled && (
        <Tooltip top="sm" align="right" width="lg">
          This feature isn&apos;t available yet. To download data from a
          filtered table, save it as a view and download it from there.
        </Tooltip>
      )}
    </>
  )
}

type ExportCSVProps = {
  showSnackbar: (toast: SnackbarOptions) => void
}

export function ExportCSV({ showSnackbar }: ExportCSVProps) {
  const router = useRouter()

  const exportCSV = (id?: string) => {
    settingsService.exportCSV(id)
    showSnackbar({
      type: 'success',
      title: 'CSV exported',
      message: 'The download of the CSV file should begin shortly.',
    })
  }

  const isFilteredList =
    Object.keys(router.query).length > 0 && !router.query.view
  const id = router.query.view ? router.query.view.toString() : undefined

  return (
    <ExportCSVButton id={id} disabled={isFilteredList} exportCSV={exportCSV} />
  )
}
