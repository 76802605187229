type DividerProps = {
  className?: string
  vertical?: boolean
}

export function Divider({ className = '', vertical = false }: DividerProps) {
  if (vertical) {
    return <div className={`w-[1px] h-[100%] bg-gray-200 ${className}`} />
  }

  return <hr className={`h-[1px] border-t border-gray-200 ${className}`} />
}
