import platform, {
  IntegrationProvider,
  Provider,
} from 'entities/utils/providerHelper'

export type AvatarProps = {
  avatarName: Provider | IntegrationProvider
  size?: number
}

export function Avatar({ avatarName, size = 24 }: AvatarProps) {
  const src = platform.getImgSrc(avatarName) || 'unknown platform'

  return (
    <img
      src={src}
      alt={`${avatarName} logo`}
      width={size}
      height={size}
      className="rounded-full border border-gray-100"
    />
  )
}
