import { lazy } from 'react'

export const ChooseCloud = lazy(async () => {
  const { ChooseCloud } = await import('pages/onboarding/choose-cloud')
  return { default: ChooseCloud }
})

export const ChooseDatabase = lazy(async () => {
  const { ChooseDatabase } = await import('pages/onboarding/choose-database')
  return { default: ChooseDatabase }
})

export const OnboardingCloudAccounts = lazy(async () => {
  const { OnboardingCloudAccounts } = await import(
    'pages/onboarding/cloud-accounts'
  )
  return { default: OnboardingCloudAccounts }
})

export const OnboardingComplete = lazy(async () => {
  const { OnboardingComplete } = await import('pages/onboarding/complete')
  return { default: OnboardingComplete }
})

export const PostgreSQLCredentials = lazy(async () => {
  const { PostgreSQLCredentials } = await import(
    'pages/onboarding/database/postgres'
  )
  return { default: PostgreSQLCredentials }
})

export const SqliteCredentials = lazy(async () => {
  const { SqliteCredentials } = await import('pages/onboarding/database/sqlite')
  return { default: SqliteCredentials }
})

export const AWSCredentials = lazy(async () => {
  const { AWSCredentials } = await import('pages/onboarding/provider/aws')
  return { default: AWSCredentials }
})

export const AzureCredentials = lazy(async () => {
  const { AzureCredentials } = await import('pages/onboarding/provider/azure')
  return { default: AzureCredentials }
})

export const CivoCredentials = lazy(async () => {
  const { CivoCredentials } = await import('pages/onboarding/provider/civo')
  return { default: CivoCredentials }
})

export const DigitalOceanCredentials = lazy(async () => {
  const { DigitalOceanCredentials } = await import(
    'pages/onboarding/provider/digitalocean'
  )
  return { default: DigitalOceanCredentials }
})

export const GcpCredentials = lazy(async () => {
  const { GcpCredentials } = await import('pages/onboarding/provider/gcp')
  return { default: GcpCredentials }
})

export const KubernetesCredentials = lazy(async () => {
  const { KubernetesCredentials } = await import(
    'pages/onboarding/provider/kubernetes'
  )
  return { default: KubernetesCredentials }
})

export const LinodeCredentials = lazy(async () => {
  const { LinodeCredentials } = await import('pages/onboarding/provider/linode')
  return { default: LinodeCredentials }
})

export const MongoDBAtlasCredentials = lazy(async () => {
  const { MongoDBAtlasCredentials } = await import(
    'pages/onboarding/provider/mongodbatlas'
  )
  return { default: MongoDBAtlasCredentials }
})

export const OciCredentials = lazy(async () => {
  const { OciCredentials } = await import('pages/onboarding/provider/oci')
  return { default: OciCredentials }
})

export const ScalewayCredentials = lazy(async () => {
  const { ScalewayCredentials } = await import(
    'pages/onboarding/provider/scaleway'
  )
  return { default: ScalewayCredentials }
})

export const TencentCredentials = lazy(async () => {
  const { TencentCredentials } = await import(
    'pages/onboarding/provider/tencent'
  )
  return { default: TencentCredentials }
})
