import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import { paths } from 'entities/config'

import {
  Recommendations,
  CloudAccounts,
  Dashboard,
  Explorer,
  Inventory,
} from 'pages/dashboard-hub'
import { Home } from 'pages/home'
import {
  ChooseCloud,
  AWSCredentials,
  AzureCredentials,
  ChooseDatabase,
  CivoCredentials,
  DigitalOceanCredentials,
  GcpCredentials,
  KubernetesCredentials,
  LinodeCredentials,
  MongoDBAtlasCredentials,
  OciCredentials,
  OnboardingCloudAccounts,
  OnboardingComplete,
  PostgreSQLCredentials,
  ScalewayCredentials,
  SqliteCredentials,
  TencentCredentials,
} from 'pages/onboarding'

import { DashboardLayout } from '../../../layout/DashboardLayout'
import { RouteGuard } from '../RouteGuard'

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<DashboardLayout />}>
      <Route
        path={paths.home}
        element={
          <RouteGuard>
            <Home />
          </RouteGuard>
        }
      />
      <Route
        path={paths.cloudAccounts}
        element={
          <RouteGuard privateRoute>
            <CloudAccounts />
          </RouteGuard>
        }
      />
      <Route
        path={paths.dashboard}
        element={
          <RouteGuard privateRoute>
            <Dashboard />
          </RouteGuard>
        }
      />
      <Route
        path={paths.inventory}
        element={
          <RouteGuard privateRoute>
            <Inventory />
          </RouteGuard>
        }
      />
      <Route
        path={paths.incidents}
        element={
          <RouteGuard privateRoute>
            <div className="p-4">Incident</div>
          </RouteGuard>
        }
      />
      <Route
        path={paths.explorer}
        element={
          <RouteGuard privateRoute>
            <Explorer />
          </RouteGuard>
        }
      />
      <Route
        path={paths.tags}
        element={
          <RouteGuard privateRoute>
            <div className="p-4">Tags</div>
          </RouteGuard>
        }
      />

      <Route
        path={paths.recommendations}
        element={
          <RouteGuard privateRoute>
            <Recommendations />
          </RouteGuard>
        }
      />

      <Route
        path={paths.reports}
        element={
          <RouteGuard privateRoute>
            <div className="p-4">Reports</div>
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingChoseCloud}
        element={
          <RouteGuard onboardingRoute>
            <ChooseCloud />
          </RouteGuard>
        }
      />
      <Route
        path={paths.onboardingChooseDatabase}
        element={
          <RouteGuard onboardingRoute>
            <ChooseDatabase />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingComplete}
        element={
          <RouteGuard onboardingRoute>
            <OnboardingComplete />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingCloudAccounts}
        element={
          <RouteGuard onboardingRoute>
            <OnboardingCloudAccounts />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingDatabaseSqlite}
        element={
          <RouteGuard onboardingRoute>
            <SqliteCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingDatabasePostgres}
        element={
          <RouteGuard onboardingRoute>
            <PostgreSQLCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderAws}
        element={
          <RouteGuard onboardingRoute>
            <AWSCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderAzure}
        element={
          <RouteGuard onboardingRoute>
            <AzureCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderCivo}
        element={
          <RouteGuard onboardingRoute>
            <CivoCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderDigitalocean}
        element={
          <RouteGuard onboardingRoute>
            <DigitalOceanCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderGcp}
        element={
          <RouteGuard onboardingRoute>
            <GcpCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderKubernetes}
        element={
          <RouteGuard onboardingRoute>
            <KubernetesCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderLinode}
        element={
          <RouteGuard onboardingRoute>
            <LinodeCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderMongoDBAtlas}
        element={
          <RouteGuard onboardingRoute>
            <MongoDBAtlasCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderOci}
        element={
          <RouteGuard onboardingRoute>
            <OciCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderScaleWay}
        element={
          <RouteGuard onboardingRoute>
            <ScalewayCredentials />
          </RouteGuard>
        }
      />

      <Route
        path={paths.onboardingProviderTencent}
        element={
          <RouteGuard onboardingRoute>
            <TencentCredentials />
          </RouteGuard>
        }
      />
    </Route>
  )
)

export default Router
