import { ComponentProps } from 'react'

import { CardSkeleton } from './CardSkeleton'
import { SkeletonFilters } from './SkeletonFilters'
import { SkeletonInventory } from './SkeletonInventory'

const DefaultSkeleton = CardSkeleton
type SkeletonType = typeof DefaultSkeleton

export const SkeletonMapping = {
  Filter: SkeletonFilters,
  Inventory: SkeletonInventory,
  Card: CardSkeleton,
} satisfies Record<string, SkeletonType>

type SkeletonMappingType = typeof SkeletonMapping

export type SkeletonTypes = keyof SkeletonMappingType

export type OwnProps<TType extends SkeletonTypes> = { type?: TType }

export function Skeleton<TType extends SkeletonTypes>({
  type,
  ...rest
}: OwnProps<TType> & ComponentProps<SkeletonMappingType[TType]>) {
  const SkeletonType = type ? SkeletonMapping[type] : DefaultSkeleton

  return <SkeletonType {...rest} />
}
