import { createContext } from 'react'

export type GlobalData = {
  resources: number
  regions: number
  costs: number
  accounts: number
}

export type GlobalAppContextProps = {
  loading: boolean
  data: GlobalData | undefined
  error: boolean
  hasNoAccounts: boolean
  fetch: () => void
  betaFlagOnboardingWizard: boolean
}

export const initialContext = {
  loading: false,
  data: undefined,
  error: false,
  hasNoAccounts: false,
  fetch: () => {},
  betaFlagOnboardingWizard: false,
}

const GlobalAppContext = createContext<GlobalAppContextProps>(initialContext)

export default GlobalAppContext
