import { lazy } from 'react'

export const Recommendations = lazy(async () => {
  const { Recommendations } = await import(
    'pages/dashboard-hub/recommendations'
  )
  return { default: Recommendations }
})

export const CloudAccounts = lazy(async () => {
  const { CloudAccounts } = await import('pages/dashboard-hub/cloud-accounts')
  return { default: CloudAccounts }
})

export const Dashboard = lazy(async () => {
  const { Dashboard } = await import('pages/dashboard-hub/dashboard')
  return { default: Dashboard }
})

export const Explorer = lazy(async () => {
  const { Explorer } = await import('pages/dashboard-hub/explorer')
  return { default: Explorer }
})

export const Inventory = lazy(async () => {
  const { Inventory } = await import('pages/dashboard-hub/inventory')
  return { default: Inventory }
})
