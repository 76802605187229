import { useState } from 'react'

import { Button, Link } from 'shared/components'
import { useRouter } from 'shared/hooks'

import { BusinessIcon, BusinessIconName } from 'entities/components'
import { paths } from 'entities/config'

import { useNavigationState } from '../navigation-context'

type NavItemData = {
  label: string
  path: string
  icon: BusinessIconName
}

type NavItemProps = { className?: string } & NavItemData

const navItems: NavItemData[] = [
  {
    label: 'Dashboard',
    path: paths.dashboard,
    icon: 'Dashboard',
  },
  {
    label: 'Inventory',
    path: paths.inventory,
    icon: 'Inventory',
  },
  {
    label: 'Incidents',
    path: paths.incidents,
    icon: 'Incidents',
  },
  {
    label: 'Explorer',
    path: paths.explorer,
    icon: 'Explorer',
  },
  {
    label: 'Tags',
    path: paths.tags,
    icon: 'Tags',
  },

  {
    label: 'Recommen dations',
    path: paths.recommendations,
    icon: 'Recommendations',
  },
  {
    label: 'Reports',
    path: paths.reports,
    icon: 'Reports',
  },
]

const cloudRoute: NavItemData = {
  label: 'Cloud Accounts',
  path: paths.cloudAccounts,
  icon: 'CloudAccount',
}

function NavItem({ icon, label, path, className = '' }: NavItemProps) {
  const router = useRouter()

  const { toggleSubMenu } = useNavigationState()

  const handleNavClick = () => {
    if (router.pathname === path) {
      toggleSubMenu()
    }
  }

  return (
    <Link
      key={label}
      href={path}
      className={`${className} flex flex-col items-center gap-1 ${
        router.pathname === path ? '' : 'opacity-50'
      }`}
      onClick={handleNavClick}
    >
      <div
        className={`w-[45px] h-[45px] p-2 rounded-lg ${router.pathname === path ? 'bg-mono-white/40' : ''}`}
      >
        <BusinessIcon name={icon} className="text-mono-white" />
      </div>

      <span className="text-mono-white text-center text-xs text-mono-white">
        {label}
      </span>
    </Link>
  )
}

export function NavigationBar() {
  const router = useRouter()

  const [query, setQuery] = useState('')
  const {
    subNavMenuConfig: {
      defaultSubMenu,
      restMenu,
      hideDefaultSubMenu,
      showSubMenu,
      actionButton,
    },
    toggleSubMenu,
  } = useNavigationState()

  const filterSubMenu = restMenu.filter(({ label }) => {
    return label.toLowerCase().includes(query.toLowerCase())
  })

  return (
    <>
      <nav className="py-4 px-2 flex flex-col items-center gap-3 bg-primary-600 min-w-[90px] max-w-[90px] h-[calc(100vh-56px)]">
        {navItems.map((navItem) => (
          <NavItem {...navItem} />
        ))}

        <NavItem className="flex-grow justify-end" {...cloudRoute} />
      </nav>

      <nav
        className={`fixed bottom-0 left-[90px] top-[56px] z-20 py-6 flex flex-col gap-4 bg-white overflow-hidden transition-all ease-in-out  ${
          showSubMenu ? 'w-[272px] px-6 duration-300' : 'w-0 duration-0 px-0'
        }`}
      >
        <div className="relative">
          <input
            placeholder="Search views"
            value={query}
            maxLength={64}
            onChange={(e) => setQuery(e.target.value)}
            className="flex w-full items-center rounded-lg border border-gray-300 px-4 py-3 pl-10 text-sm font-medium text-gray-700 transition-colors hover:border-gray-500 focus-visible:outline-darkcyan-500"
          />
          <div
            className={`absolute left-4 top-[0.95rem] ${
              query ? 'cursor-pointer' : ''
            }`}
          >
            {!query ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M11.5 21a9.5 9.5 0 100-19 9.5 9.5 0 000 19zM22 22l-2-2"
                />
              </svg>
            ) : (
              <svg
                onClick={() => setQuery('')}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
                className="hover:bg-gray-50"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M7.757 16.243l8.486-8.486M16.243 16.243L7.757 7.757"
                />
              </svg>
            )}
          </div>
        </div>
        <div className="-mx-4 -mr-6 flex flex-col gap-4 overflow-auto px-4 pr-6">
          {!hideDefaultSubMenu && (
            <Button
              noStyles
              onClick={() => {
                router.push(router.pathname)
              }}
              className={`flex items-center gap-3 rounded-lg px-4 py-3 text-left text-sm font-medium ${
                !router.query.view
                  ? 'border-l-2 border-darkcyan-500 bg-cyan-100 text-darkcyan-500'
                  : 'text-gray-700 transition-colors hover:bg-gray-50'
              }`}
            >
              <div className={!router.query.view ? 'ml-[-2px]' : ''}>
                <p className="w-[192px] truncate">{defaultSubMenu}</p>
              </div>
            </Button>
          )}
          {filterSubMenu.map(({ view, label }) => {
            const isActive = router.query.view === view

            return (
              <Button
                key={view}
                noStyles
                onClick={() => {
                  if (isActive) return
                  router.push(`?view=${view}`)
                }}
                className={`flex items-center gap-3 rounded-lg px-4 py-3 text-left text-sm font-medium ${
                  isActive
                    ? 'border-l-2 border-darkcyan-500 bg-cyan-100 text-darkcyan-500'
                    : 'text-gray-700 transition-colors hover:bg-gray-50'
                }`}
              >
                <div className={isActive ? 'ml-[-2px]' : ''}>
                  <p className="w-[188px] truncate">{label}</p>
                </div>
              </Button>
            )
          })}
        </div>
        {actionButton && (
          <div className="flex flex-col justify-end absolute bottom-10 border-t border-gray-300 p-4">
            <Button onClick={actionButton.onClick}>{actionButton.label}</Button>
          </div>
        )}
      </nav>

      {showSubMenu && (
        <div
          className="fixed left-[362px] z-20 top-[56px] right-0 bottom-0"
          onClick={toggleSubMenu}
        />
      )}
    </>
  )
}
