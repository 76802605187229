import { createContext, useContext } from 'react'

export type SnackbarOptions = {
  type?: 'success' | 'error' | 'info' // TODO: add 'warning','info
  title: string
  message: string
}

export type SnackbarContextProps = {
  showSnackbar: (options: SnackbarOptions) => void
  dismissSnackbar: () => void
  snackbar: SnackbarOptions | null
}

const initialSnackbarContext: SnackbarContextProps = {
  dismissSnackbar: () => {},
  showSnackbar: () => {},
  // TODO: Implement info, warning, and error in place of show as more precise implementation
  snackbar: null,
}

export const SnackbarContext = createContext<SnackbarContextProps>(
  initialSnackbarContext
)

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)
  return context
}
