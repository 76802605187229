function Card() {
  return (
    <div className="flex h-[7.5rem] animate-pulse items-center rounded-lg bg-white px-6 text-sm">
      <div className="flex w-full gap-6">
        <div className="h-10 w-10 flex-shrink-0 rounded-xl bg-cyan-200" />
        <div className="flex w-full flex-col gap-3">
          <div className="h-4 w-[36%] rounded-lg bg-cyan-200" />
          <div className="h-4 w-[86%] rounded-lg bg-cyan-200" />
        </div>
      </div>
    </div>
  )
}

type CardSkeletonProps = {
  numOfCards?: number
  single?: boolean
}

export function CardSkeleton({
  numOfCards = 3,
  single = false,
}: CardSkeletonProps) {
  const cards: number[] = Array.from(Array(numOfCards).keys())

  if (single) {
    return <Card />
  }

  return (
    <div
      className={`grid-col grid gap-8 md:grid-cols-2 ${
        numOfCards === 4 ? 'lg:grid-cols-4' : 'lg:grid-cols-3'
      }`}
    >
      {cards.map((card) => (
        <Card key={card} />
      ))}
    </div>
  )
}
