import { ReactNode, useEffect } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { useLocalState, useRouter } from 'shared/hooks'
import { ExclusiveProperty } from 'shared/typescript'

import { paths } from 'entities/config'
import { useOnboardingStatus } from 'entities/state'

type Props = { children: ReactNode } & Partial<
  ExclusiveProperty<{
    privateRoute: boolean
    onboardingRoute: boolean
    authRoute: boolean
  }>
>

// FIXME: When login is implemented, refactor this code
export function RouteGuard({
  privateRoute,
  onboardingRoute,
  authRoute,
  children,
}: Props) {
  /**
   * Retrieve the isLoggedIn state from local storage
   * Replace this as per session management
   * @todo To be fixed
   * TODO: Path from config
   */
  const [isLoggedIn] = useLocalState('isLoggedIn', true)

  const { onboardingData, isLoading } = useOnboardingStatus()
  const router = useRouter()

  useEffect(() => {
    if (onboardingData) {
      const { onboarded, status } = onboardingData

      // Can go to onboarding route even after onboarding is complete
      // TODO: Clear out the use case
      if (onboardingRoute && onboarded === true && status === 'COMPLETE') {
        // router.replace('/dashboard/')
        return
      }

      if (privateRoute && onboarded === false) {
        if (status === 'PENDING_DATABASE') {
          router.replace('/onboarding/choose-database')
        } else if (status === 'PENDING_ACCOUNTS') {
          router.replace('/onboarding/choose-cloud')
        }
      }
    }
  }, [onboardingData, onboardingRoute, privateRoute, router])

  const path = useLocation()
  const { state } = path

  // if (privateRoute && !isLoggedIn) {
  //   return <Navigate to={paths.signIn} state={{ redirectUrl: path }} replace />
  // }

  if (isLoading) {
    return <div>Loading... </div>
  }

  if (authRoute && isLoggedIn) {
    return <Navigate to={state?.redirectUrl ?? paths.dashboard} replace />
  }

  return children
}
