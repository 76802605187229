import { ReactNode } from 'react'

export type GridProps = {
  children: ReactNode
  gap?: 'sm' | 'md' | 'lg'
}

export function Grid({ children, gap = 'lg' }: GridProps) {
  return (
    <div
      className={`grid grid-cols-1 ${gap === 'sm' ? 'gap-4' : ''} ${
        gap === 'md' ? 'gap-6' : ''
      } ${gap === 'lg' ? 'gap-8' : ''} lg:grid-cols-2`}
    >
      {children}
    </div>
  )
}
