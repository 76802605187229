export const ACCESS_TOKEN = 'access-token'
export const REFRESH_TOKEN = 'refresh-token'

// FIXME: Add as per your API endpoint
export const loginEndpoint = 'v1/auth/login'
export const refreshTokenEndpoint = 'v1/auth/refresh-token'

type UserSessionActive = {
  accessToken: string
  refreshToken: string
}

export const userSessionActive = (value: UserSessionActive) => {
  localStorage.setItem(ACCESS_TOKEN, value.accessToken)
  localStorage.setItem(REFRESH_TOKEN, value.refreshToken)
}

export const userSessionInactive = () => {
  localStorage.clear()
}
