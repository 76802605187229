import { createContext, useContext } from 'react'

import { SuggestionString } from 'shared/typescript'

export type ExpandedSideNavData = {
  label: string
  view: string
}

export type SubNavMenuConfig = {
  defaultSubMenu: SuggestionString<'Show All'>
  restMenu: ExpandedSideNavData[]
  hideDefaultSubMenu?: boolean
  showSubMenu?: boolean
  actionButton?: {
    label: string
    onClick: () => void
  }
}

export const initialSubNavMenuConfig: SubNavMenuConfig = {
  defaultSubMenu: 'Show All',
  restMenu: [],
  hideDefaultSubMenu: false,
  showSubMenu: false,
}

export type NavigationContextProps = {
  subNavMenuConfig: SubNavMenuConfig
  setSubNavMenuConfig: (props: SubNavMenuConfig) => void
  toggleSubMenu: () => void
}

export const initialContext: NavigationContextProps = {
  subNavMenuConfig: { ...initialSubNavMenuConfig },
  setSubNavMenuConfig: () => {},
  toggleSubMenu: () => {},
}

export const NavigationContext =
  createContext<NavigationContextProps>(initialContext)

export const useNavigationState = () =>
  useContext<NavigationContextProps>(NavigationContext)
