import CloudAccount from './icons/cloud-account.svg'
import Dashboard from './icons/dashboard.svg'
import Explorer from './icons/explorer.svg'
import Incidents from './icons/incidents.svg'
import Inventory from './icons/inventory.svg'
import Recommendations from './icons/recommendations.svg'
import Reports from './icons/reports.svg'
import Tags from './icons/tags.svg'

export const BusinessIcons = {
  CloudAccount,
  Dashboard,
  Explorer,
  Inventory,
  Recommendations,
  Incidents,
  Reports,
  Tags,
}
