export const paths = {
  home: '/',
  signIn: '/sign-in',
  signUp: '/sign-up',
  // dashboard: '/dashboard',
  account: '/account',

  cloudAccounts: '/cloud-accounts',
  dashboard: '/dashboard',
  inventory: '/inventory',
  incidents: '/incidents',
  explorer: '/explorer',
  tags: '/tags',
  recommendations: '/recommendations',
  reports: '/reports',

  // onboarding: '/onboarding',
  onboardingChoseCloud: '/onboarding/choose-cloud',
  onboardingChooseDatabase: '/onboarding/choose-database',
  onboardingComplete: '/onboarding/complete',
  onboardingCloudAccounts: '/onboarding/cloud-accounts',
  onboardingDatabasePostgres: '/onboarding/database/postgres',
  onboardingDatabaseSqlite: '/onboarding/database/sqlite',
  onboardingProviderAws: '/onboarding/provider/aws',
  onboardingProviderAzure: '/onboarding/provider/azure',
  onboardingProviderCivo: '/onboarding/provider/civo',
  onboardingProviderDigitalocean: '/onboarding/provider/digitalocean',
  onboardingProviderGcp: '/onboarding/provider/gcp',
  onboardingProviderKubernetes: '/onboarding/provider/kubernetes',
  onboardingProviderLinode: '/onboarding/provider/linode',
  onboardingProviderMongoDBAtlas: '/onboarding/provider/mongodbatlas',
  onboardingProviderOci: '/onboarding/provider/oci',
  onboardingProviderScaleWay: '/onboarding/provider/scaleway',
  onboardingProviderTencent: '/onboarding/provider/tencent',
}
