export type ImageProps = {
  src: string
  alt: string
  width?: number | string
  height?: number | string
  className?: string
}

export function Image({
  src,
  alt,
  width = 'auto',
  height = 'auto',
  className = '',
}: ImageProps) {
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
    />
  )
}
