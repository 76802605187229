import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { NavigationBar } from '../NavigationBar'
import {
  NavigationContext,
  NavigationContextProps,
  SubNavMenuConfig,
  initialSubNavMenuConfig,
} from '../navigation-context'

type Props = { children: React.ReactNode }

type ConfigState = SubNavMenuConfig & { loadedPath: string }
type InterimConfigState = SubNavMenuConfig & {
  state: 'initialized' | 'cleared'
}

export function NavigationProvider({ children }: Props) {
  const location = useLocation()

  const [subNavMenuConfig, setSubNavMenuConfig] = useState<ConfigState>({
    ...initialSubNavMenuConfig,
    loadedPath: '',
  })

  const [interimSubNavMenuConfig, setInterimSubNavMenuConfig] =
    useState<InterimConfigState>({
      ...initialSubNavMenuConfig,
      state: 'cleared',
    })

  const updateInterimSubNavMenuConfig = useCallback(
    (navState: SubNavMenuConfig) => {
      setInterimSubNavMenuConfig({
        hideDefaultSubMenu: false,
        showSubMenu: true,
        state: 'initialized',
        ...navState,
      })
    },
    []
  )

  // Resetting on route change (page change) if loadedPath is not the same as the current path
  useEffect(() => {
    if (
      !!subNavMenuConfig.loadedPath &&
      location.pathname !== subNavMenuConfig.loadedPath
    ) {
      setSubNavMenuConfig({
        ...initialSubNavMenuConfig,
        loadedPath: '',
      })
    }
  }, [location.pathname, subNavMenuConfig])

  // Setting the subNavMenuConfig when the interimSubNavMenuConfig is initialized
  useEffect(() => {
    if (interimSubNavMenuConfig.state === 'initialized') {
      setInterimSubNavMenuConfig((subMenuConfig) => {
        setSubNavMenuConfig({ ...subMenuConfig, loadedPath: location.pathname })
        return { ...initialSubNavMenuConfig, state: 'cleared' } // clearing the interim state after setting the subNavMenuConfig
      })
    }
  }, [interimSubNavMenuConfig, location.pathname, setSubNavMenuConfig])

  const contextValue = useMemo(() => {
    const result: NavigationContextProps = {
      subNavMenuConfig,
      setSubNavMenuConfig: updateInterimSubNavMenuConfig,
      toggleSubMenu: () => {
        setSubNavMenuConfig((prevState) => ({
          ...prevState,
          showSubMenu: !prevState.showSubMenu,
        }))
      },
    }

    return result
  }, [subNavMenuConfig, updateInterimSubNavMenuConfig])

  return (
    <NavigationContext.Provider value={contextValue}>
      <NavigationBar />
      {children}
    </NavigationContext.Provider>
  )
}
