import { ExclusiveProperty } from 'shared/typescript'

type SpacingProps = {
  size?: 'sm' | 'md' | 'lg'
} & ExclusiveProperty<{
  vertical: true
  horizontal: true
}>

/**
 * TODO: Refactor this component, add remaining spacing sizes
 */
export function Spacing({ size = 'md', vertical }: SpacingProps) {
  if (vertical) {
    return <div className={size === 'md' ? 'mt-6' : ''} />
  }

  return <div className={size === 'md' ? 'mr-6' : ''} />
}
