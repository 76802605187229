import { useCallback, useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { PartialRecord } from '../../typescript'

type Query = PartialRecord<string, string>

type Push = (
  url: string,
  _as?: string,
  _options?: { shallow?: boolean }
) => void

// FIXME: rename this to something else
// TODO: Need to work on the integration - its a replica of next/router as code is migrated from next to react-router-dom

export type NextRouter = {
  query: Query
  push: Push
  /**
   * full location with query params
   */
  asPath: string
  reload: () => void
  replace: (url: string) => void
  back: () => void
  pathname: string
}

export const useRouter = (): NextRouter => {
  const navigate = useNavigate()
  // const [searchParams] = useSearchParams()
  const location = useLocation()

  const push: Push = useCallback(
    (url, _as, _options?) => {
      return navigate(url)
    },
    [navigate]
  )

  const query = useMemo(
    () =>
      (location.search || '')
        .split('?')[1]
        ?.split('&')
        .reduce((acc, item) => {
          const [key, value] = item.split('=')
          return { ...acc, [key]: value }
        }, {} as Query) ?? ({} as Query),
    [location.search]
  )

  const reload = useCallback(() => {
    navigate(0)
  }, [navigate])

  const back = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const replace = useCallback(
    (url: string) => {
      navigate(url, { replace: true })
    },
    [navigate]
  )

  const result: NextRouter = useMemo(
    () => ({
      push,
      reload,
      back,
      query,
      asPath: `${location.pathname}/${location.search}`,
      pathname: location.pathname,
      replace,
    }),
    [back, location.pathname, location.search, push, query, reload, replace]
  )

  return result
}
